window.validatePost = validatePost ?? {}
window.validateField = validateField ?? {}

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = document.location.host; // host + port
    var protocol = document.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

// **************************** //
// ******* FUNCTIONALITY ****** //
// **************************** //

$.ajaxSetup({
    cache: false,
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
            // Send the token to same-origin, relative URLs only.
            // Send the token only if the method warrants CSRF protection
            // Using the CSRFToken value acquired earlier
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    }
});

/*
 * Validates array on criteria
 * attribute ID, matching criteria, response
 * */
function validatePost() {
    var ret = true;
    ret = validateField($('#id_name'));
    if (ret) {
        ret = validateField($('#id_body'));
    }

    return ret;
}
function validateField(field) {
    if (field.prev('label.placeholder').html() == field.val() || field.val() == "") {
        alert(field.attr('validate'));
        field.focus();
        return false;
    } else {
        return true;
    }
}

$.cachedScript = function (url, options) {
    /* Allow user to set any option except for dataType, cache, and url */
    options = $.extend(options || {}, {
        dataType: "script",
        cache: true,
        url: url
    });
    /* Use $.ajax() since it is more flexible than $.getScript
        Return the jqXHR object so we can chain callbacks */
    return $.ajax(options);
};

var doEllipsis = function (selector) {

    selector = typeof selector !== 'undefined' ? selector : '';

    // Append a space to the end of selector if one doesn't exist
    if ((selector.length > 1) && (selector.endsWith(' ') == false)) {
        selector += ' ';
    }

    // Simple multi-line capable ellipsis with no read-more link
    $(selector + ".ellipsis-no-more").dotdotdot({ debug: false });

    /*
    1) Store the unexpanded heights of all of the text blocks
    2) Append a read-more anchor to all of the ellipsis-more blocks
    3) Execute dotdotdot
    4) Clean up a.read-more anchors that weren't necessary
    */

    $(selector + '.ellipsis-more').each(function () {
        $(this).data('expandedHeight', $(this)[0].scrollHeight);
    }).append('<a class="read-more"> ' + gTerms.more + '</a>').dotdotdot({
        after: 'a.read-more',
        debug: false
    }).each(function () {
        if ($(this).triggerHandler("isTruncated") == false) {
        $(this).find('a.read-more').css('display', 'none');
        }
    });

    $(".ellipsis-more a.read-more").click(function (e) {
        e.preventDefault();
        el = $(this).closest('.ellipsis-more');
        el.css('height', el.css('max-height'))
        .animate({
            height: el.data('expandedHeight')
        }, 1, function() {
            $(this)
            .trigger('destroy')
            .css('height', 'auto')
            .css('max-height', 'none')
            .find('.read-more')
            .css('display', 'none')
        })
    });
};

$(document).ready(function() {

    $('form').attr('autocomplete', 'off');
    // mobile nav tabs //
    $('#main_tabs .nav_pills, #main-tabs.nav-tabs').tabdrop({ text: ' <i class="cux cux-bars"></i>' });

    // Create the dropdown base
    $("<select id='dropNav' aria-label='Mobile Quick Navigation' class=\"d-none form-control visible-xs visible-sm\" />").appendTo($("#main-tabs").parent());

    // Mobile Nav - Create default option "Go to..."
    $("<option />", {
        "selected": "selected",
        "value": "",
        "text": "Go to..."
    }).appendTo("select#dropNav");

    // Mobile Nav - Populate dropdown with menu items
    $("#main-tabs > li").each(function () {
        var el = $(this);

        // if item is not tabdrop-dropdown btn
        if (!el.hasClass('tabdrop')) {

            var hasChildren = el.find("ul"),
                children = el.find("a");

            if (hasChildren.length) {

                $("<optgroup />", {
                "label": el.find("> a").text()
                }).appendTo("select#dropNav");

                children.each(function () {
                    if (!$(this).hasClass('dropdown-toggle')) {
                        $("<option />", {
                        "value": $(this).attr("href"),
                        "text": " - " + $(this).text()
                        }).appendTo("optgroup:last");
                    }
                });

            } else {
                $("<option />", {
                "value": el.find("a").attr("href"),
                "text": el.find("a").text()
                }).appendTo("select#dropNav");
            }
        }
    });

    // When the user scrolls down, resize the header's font size
    window.onscroll = function () { scrollFunction() };
    scrollFunction();

    function scrollFunction() {
        if (document.body.scrollTop > 35 || document.documentElement.scrollTop > 35) {
            document.getElementById("headerWrapper").classList.add('shrink');
        } else {
            document.getElementById("headerWrapper").classList.remove('shrink');
        }
    }

    // if user clicked to open a MM, increase zindex on curent MM
    $(document).on('mouseenter', '#primary-nav .nav-item.dropdown.mega-dropdown.lev-1', function () {
        if ($('.nav-item.dropdown.mega-dropdown.lev-1.open').length > 0) {
            $('.nav-item.z-high').removeClass('z-high');
            $(this).addClass('z-high');
        }
    });
    // navigation hover
    $(document).on('mouseenter', '#primary-nav [data-toggle="tab"]', function () {
        if (!$(this).hasClass('active')) {
            $(this).tab('show');
            // on parent switch, select related Current Course (first nested tab)
            if ($(this).data('state') == 'parent') {
                const target = $(this).attr('aria-controls');
                $("#" + target).find('ul li:first()').tab('show');
            }
        }
    });
    // naigation keyboard controls
    // course list keyboard controls
    $(document).on('keydown', '.course-link', function (e) {
        // const thisClass = $(this).attr('class');
        // console.log('course-link bindings... e.keyCode: ', e.keyCode, '... thisclass: ', thisClass);
        // if left
        if (e.keyCode == 37) {
            // console.log('left');
            $(this).closest('.megamenu').find('[data-toggle="tab"].nav-item.active:last a').focus();
        }
    });
    // tab list controls
    $(document).on('keydown', '[data-toggle="tab"]', function (e) {
        const thisClass = $(this).attr('class');
        const target = $(this).attr('aria-controls');
        console.log('tab-pane bindings... e.keyCode: ', e.keyCode, '... target: ', target, '... thisclass: ', thisClass);
        if (!target) { return }
        // if left
        if (e.keyCode == 37) {
            const href = '#' + $(this).closest('ul').parent().attr('id');
            $('[href="' + href + '"] a').focus();
            // elif right or return key
        } else if (e.keyCode == 13 || e.keyCode == 39) {
            // if first level, select current course on course type switch
            if ($(this).data('state') == 'parent') {
                $(this).addClass('active').tab('show');
                $("#" + target).find('ul li:first()').tab('show').on('shown.bs.tab', function () {
                $("#" + target).find('ul li:first() a').focus();
                });
            }
            // else select corresponding tab to manually show due to stop
            else {
                $(this).addClass('active').tab('show').on('shown.bs.tab', function () {
                $("#" + target).find('li:first() a:first()').focus();
                });
            }
            // stops the dropdown from closing
            e.preventDefault();
        }
    });

    // Mobile Nav
    $("select#dropNav").change(function () {
        window.location = $(this).find("option:selected").val();
    });

    var modalAXCtrls = new function () {
        /** JQuery Back Link Element */
        this.link = null;
        /** Modal HTMLElement */
        this.modal = null;

        var focusableElements = 'button:not([disabled]), [href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'

        this.fetchTabingElements = function () {
            var focusableContents = Array.from(this.modal.querySelectorAll(focusableElements)).filter(function(el) {
                var rect = el.getBoundingClientRect();
                // console.log({
                //   el: el,
                //   rect: rect,
                //   top: rect.top >= 0,
                //   right: rect.right <= (window.innerWidth || document.documentElement.clientWidth),
                //   bottom: rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
                //   left: rect.left >= 0,
                //   width: rect.width > 0,
                //   height: rect.height > 0,
                //   result: (
                //     rect.top >= 0 &&
                //     rect.left >= 0 &&
                //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                //   ) && (
                //       rect.width > 0 && rect.height > 0
                //     )
                // });
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                ) && (
                    rect.width > 0 && rect.height > 0
                )
            });
            
            // console.log('Focusable Contents', focusableContents);
            this.firstFocusElement = focusableContents[0];
            this.lastFocusElement = focusableContents[focusableContents.length - 1];
            // console.log({
            //   first: this.firstFocusElement,
            //   last: this.lastFocusElement
            // });
        }

        this.tabPress = function (e) {
            var isTabPressed = e.key === 'Tab' || e.code === 9;
            if (!isTabPressed) { return; }
            // console.log('Focused:', document.activeElement);
            this.fetchTabingElements();
            if (e.shiftKey) {
                if (document.activeElement === this.firstFocusElement) {
                    // console.log('Focusing Last Element');
                    this.lastFocusElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === this.lastFocusElement) {
                    // console.log('Focusing First Element');
                    this.firstFocusElement.focus();
                    e.preventDefault();
                }
            }
        }

        this.boundTabPress = this.tabPress.bind(this);

        this.create = function (modal, backLink) {
            // console.log('Creating Modal at', modal, 'with back link', backLink);
            this.link = backLink;
            this.modal = modal;

            this.fetchTabingElements();

            var firstHeaderElement = modal.querySelector('h1, h2, h3, h4, h5, h6');
            if (firstHeaderElement) {
                firstHeaderElement.id = "modal-title";
                modal.setAttribute('aria-labelledby', 'modal-title');
            }

            document.addEventListener('keydown', this.boundTabPress);

            setTimeout((function () {
                // console.log('Focusing first element', this.firstFocusElement);
                this.firstFocusElement.focus();
            }).bind(this), 500);
        }

        this.destroy = function () {
            // console.log('Destroying modal', this.modal, 'focusing', this.link);
            document.removeEventListener('keydown', this.boundTabPress);
            if (this.link) {
                this.link.focus();
                this.link = null;
            }

            if (this.modal) {
                this.modal = null;
            }
        }
    };
    
    window.modalAXCtrls = modalAXCtrls;

    // Master v2 switch from facebox
    $("#cuxModal, #cuxModalFull, #cuxModalControl").on("show.bs.modal", function (e) {
        var modal = this;
        var link = window.modalBackLink || $(e.relatedTarget);
        $(modal).removeAttr('aria-hidden');
        if (link.attr('href')) {
            $(modal).find(".modal-body").load(link.attr("href"), function() {
                setTimeout(function() {
                    modalAXCtrls.create(modal, link);
                }, 500);
            });
        } else {
            setTimeout(function() {
                modalAXCtrls.create(modal, link);
            }, 500);
        }
    });

    $("#cuxModal, #cuxModalFull, #cuxModalControl").on("hidden.bs.modal", function () {
        $(this).find(".modal-body").empty();
        $(this).find(".modal-title").empty();
        $(this).attr('aria-hidden', 'true');
        modalAXCtrls.destroy();
        if (window.corpu.modals.blocking) {
            window.corpu.modals.blocking = false
            window.dispatchEvent(new CustomEvent('corpu:modals:blocking:end', { detail: { $el: $(this) } }))
        }
    });

    /* used for clearable file input bootstrap 3 */
    $('.fileinput').on('clear.bs.fileinput', function () {
        $(this).siblings('.fileinput-clear').attr('checked', true);
    });
    $('.fileinput').on('change.bs.fileinput', function () {
        $(this).siblings('.fileinput-clear').attr('checked', false);
    });

    // window listener for Uploader(s)
    window.addEventListener('corpu:*:uploaded', function (evt) {
        if (evt.detail) {
            // console.log('Global Event Uploaded:', evt.detail, evt.detail.elapsed);
        }
    });

    doEllipsis();
});

$(document).on("keypress", function(e) {
    // Accessibility Keyboard controls - show hidden sr-only text & add Heading Label Outlines keypress listener with contrl+shift+alt+a cntrl+shft+alt+a
    if ( e.shiftKey && e.altKey && e.ctrlKey && ( e.which === 1 ) ) {
        $('.sr-only').toggleClass('show-sr-only').append(("<span class='badge'>sr-only</span>"));    
        $( ":header" ).toggleClass('show-sr-only').each(function( ) {
            $(this).append(("<span class='badge badge-secondary'>"+$(this).prop('nodeName')+"</span>"));
        });
    }
});